import React, { useState, FC, useEffect, useRef } from 'react'
import { Grid, List, ListItem, ListItemText, Typography, Link, Box } from '@mui/material'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { Chapter, FleetDetailsResp, ChapterDocument, TRMFleet } from './TRMService'
import Icon from '../common/Icon/Icon'
import ViewPDFModal from '../package/ViewPDFModal'

export interface TRMFleetDetailsProps {
  data: FleetDetailsResp
  searchTerm: string
  selectedFleet: TRMFleet | null
}

export const TRMFleetDetails: FC<TRMFleetDetailsProps> = ({ data, searchTerm, selectedFleet }) => {
  const [selectedChapter, setSelectedChapter] = useState<Chapter | null>(null)
  const [isViewPDFModalOpen, setIsViewPDFModalOpen] = useState(false)
  const [selectedDocument, setSelectedDocuemnt] = useState<null | ChapterDocument>(null)

  const selectedChapterRef = useRef<HTMLDivElement | null>(null)

  useEffect(()=>{
    if(selectedChapterRef.current) {
      selectedChapterRef.current?.scrollIntoView({behavior:'smooth', block:'center'})
    }
  },[selectedChapter, selectedFleet])


  useEffect(()=>{

    if(selectedChapter && data) {

      const prevSelectedChapter = data?.Chapters?.find(obj=>obj?.Title?.toLowerCase() === selectedChapter?.Title?.toLowerCase());
      if(prevSelectedChapter) {
        handleChapterClick(prevSelectedChapter)
      }
      else {
        handleChapterClick(data?.Chapters[0])
      }
    }

  },[selectedFleet, data])


  const filteredChapters = data.Chapters.map(chapter => {
    // Filter documents based on the search term
    const filteredDocuments = chapter?.Documents.filter(
      doc =>
        doc.Title.toLowerCase().includes(searchTerm.toLowerCase()) ||
        doc.DocumentId.toString().includes(searchTerm) ||
        doc.ECRA.toString().includes(searchTerm)
    )

    if (filteredDocuments.length > 0) {
      return {
        ...chapter,
        Documents: filteredDocuments
      }
    }

    return null
  }).filter(chapter => chapter !== null) as Chapter[]


  const handleChapterClick = (chapter: Chapter) => {
    if (chapter) {
      setSelectedChapter(chapter)
    }
  }

  const openPDFinOtherTab = (docID: string, docTitle: string) => {
    window.open(
      `/pdfViewer?documentId=${docID}&title=${docTitle}&component=trm&fleetcode=${selectedFleet?.FleetCode}`,
      '_blank',
      'noopener noreferrer'
    )
  }

  const handleDocumentLinkClick = (docDetails: ChapterDocument) => {
    setSelectedDocuemnt(docDetails)
    setIsViewPDFModalOpen(true)
  }

  const handleClose = () => {
    setIsViewPDFModalOpen(false)
  }

  return (
    <>
      <Grid item mb={2} sx={{ height: '76vh', overflowY: 'auto', flex: 1 }}>
        <Box>
          <List>
            {filteredChapters.map((chapter, index) => (
              <ListItem
                key={index}
                button
                onClick={() => handleChapterClick(chapter)}
                selected={selectedChapter ? selectedChapter.Title === chapter?.Title : index === 0}
                ref={selectedChapter && selectedChapter.Title === chapter?.Title ? selectedChapterRef: null}
                sx={{
                  backgroundColor: (selectedChapter ? selectedChapter.Title === chapter?.Title : index === 0)
                    ? 'rgba(0, 0, 0, 0.08)'
                    : 'transparent',
                  borderRadius: '4px',
                  cursor: 'pointer',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  paddingRight: 2,
                  marginBottom: 1
                }}
              >
                <ListItemText primary={chapter?.Title} sx={{ flexGrow: 1 }} />
                <ChevronRightIcon />
              </ListItem>
            ))}
          </List>
        </Box>
      </Grid>
      <Grid item sx={{ height: '76vh', overflowY: 'auto', flex: 1 }}>
        {filteredChapters.length > 0 && searchTerm === '' ? (
          <Grid sx={{ ml: 4 }}>
            <Typography variant='h6'>{selectedChapter ? selectedChapter.Title : filteredChapters[0].Title}</Typography>
            <List>
              {(selectedChapter ? selectedChapter.Documents : filteredChapters[0].Documents).map((doc, index) => (
                <ListItem
                  key={index}
                  sx={{ borderBottom: '1px solid #666666', display: 'flex', justifyContent: 'space-between' }}
                >
                  <Link underline='hover' onClick={() => handleDocumentLinkClick(doc)}>
                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
                      <ListItemText primary={doc.ECRA} sx={{ mr: 4 }} />
                      <ListItemText primary={doc.Title} />
                    </Box>
                  </Link>

                  <Box onClick={() => openPDFinOtherTab(doc.DocumentId, doc.Title)}>
                    <Icon variant='popout' width='30px' height='40px' clickable='true' />
                  </Box>
                </ListItem>
              ))}
            </List>
          </Grid>
        ) : (
          <Grid sx={{ ml: 4 }}>
            {filteredChapters?.map((chapter, chapterIndex) => {
              return (
                <div key={chapterIndex}>
                  <Typography variant='h6'>{chapter?.Title}</Typography>
                  <List>
                    {chapter.Documents.map((doc, index) => (
                      <ListItem
                        key={index}
                        sx={{ borderBottom: '1px solid #666666', display: 'flex', justifyContent: 'space-between' }}
                      >
                        <Link
                          underline='hover'
                          onClick={() => handleDocumentLinkClick(doc)}
                        >
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                              width: '100%'
                            }}
                          >
                            <ListItemText primary={doc.ECRA} sx={{ mr: 4 }} />
                            <ListItemText primary={doc.Title} />
                          </Box>
                        </Link>
                        <Box onClick={() => openPDFinOtherTab(doc.DocumentId, doc.Title)}>
                          <Icon variant='popout' width='30px' height='40px' clickable='true' />
                        </Box>
                      </ListItem>
                    ))}
                  </List>
                </div>
              )
            })}
          </Grid>
        )}
        {filteredChapters?.length === 0 && <Typography variant='h6'>No results found!</Typography>}
        {isViewPDFModalOpen && (
          <ViewPDFModal
            documentId={selectedDocument?.DocumentId}
            title={selectedDocument?.Title}
            isOpen={isViewPDFModalOpen}
            onClose={handleClose}
            isTRMDocument={true}
            fleetcode={selectedFleet?.FleetCode}
          />
        )}
      </Grid>
    </>
  )
}
